/**
 * login
 * @author Tevin
 */

@import "../../components/common/sassMixin";
@import "../../components/common/custom.scss";
@import "../../components/common/common.scss";

.app {
    width: 100%;
    height: 100%;
    background: url('/static/manager/images/login-background.jpg') center center;
    >.ant-layout {
        background: transparent;
    }
}

.login {
    @include position(absolute, 0 0 0 0);
    width: 470px;
    max-width: 90%;
    height: 470px;
    margin: auto;
    background-color: #fff;
    border-radius: 3px;
    box-shadow: rgba(0, 0, 0, 0.1) 2px 3px 5px;
    @include prefixer(transform, translate(0, -15px));
    .login-inner {
        width: 100%;
        padding: 36px 12.77%;
        .logo {
            display: block;
            margin: 0 auto;
            max-width: 100%;
        }
        .ant-form {
            padding-top: 45px;
        }
        .ant-form-item {
            height: 62px;
            margin-bottom: 8px;
        }
        .ant-input-affix-wrapper {
            border: none;
            border-bottom: 1px solid #d9d9d9;
            border-radius: 0;
        }
        .ant-input-prefix {
            margin-right: 10px;
        }
        .ant-btn-block {
            margin-top: 15px;
        }
    }
}

.footer {
    @include position(absolute, n 0 10px n);
    width: 100%;
    padding: 0 20px;
    text-align: center;
    color: #333;
    span {
        padding: 0 16px;
        color: #999;
    }
    a {
        color: #333;
        &:hover,
        &:active {
            color: #1890ff;
            text-decoration: underline;
        }
    }
}