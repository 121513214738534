/**
 * common
 * @author Tevin
 */

@import "./sassMixin";

/* ---------- base ---------- */
body,
div,
ol,
ul,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6,
pre,
code,
form,
fieldset,
legend,
input,
textarea,
p,
blockquote,
th,
td,
hr,
button,
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
    margin: 0;
    padding: 0;
}
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
    display: block;
    word-wrap: break-word;
}
ul {
    list-style: none;
}
ol {
    padding-left: 2em;
}
fieldset,
img {
    border: 0;
    vertical-align: middle;
}
a,
img {
    -webkit-touch-callout: none;
}
input[type="button"],
input[type="submit"],
input[type="reset"],
textarea {
    -webkit-appearance: none;
}

/* ---------- common ---------- */

.no-select {
    @include prefixer(user-select, none);
}

.m-td-separate {
    border-right-color: #e4e4e4 !important;
    &.ant-table-cell-fix-right {
        border-right-color: #f0f0f0 !important;
        &::after {
            border-right-color: #e4e4e4 !important;
        }
    }
}